<template>
  <div class="home-content">
    <Tabs v-model="active" type="card" color="#0063BF" @click="changeTab">
      <Tab title="拜访" />
      <Tab title="协访" />
    </Tabs>
    <div style="height: 20px"></div>
    <div
      ref="scrollContainer"
      style="height: calc(100vh - 120px); overflow-y: scroll"
    >
      <div v-for="(item, index) in lastList" :key="index">
        <div class="date-title">{{ item.time }}</div>
        <div>
          <VisitListItem
            v-for="(sonItem, sonIndex) in item.list"
            :key="sonIndex"
            :color="changeColor(item.time)"
            :item="sonItem"
          />
        </div>
      </div>
      <Empty v-if="!lastList.length && !loading" description="无数据" />
      <Loading
        v-if="loading"
        style="margin-top: 100px"
        type="spinner"
        color="#1989fa"
      />
    </div>

    <Button
      class="floating"
      round
      type="info"
      icon="plus"
      @click="
        active === 1
          ? $router.push(`visit-create?isCoVisit=${active}`)
          : $router.push(`visit-create`)
      "
    >
      新建{{ ['拜访', '协访'][active] }}
    </Button>
    <div style="height: 50px"></div>
  </div>
</template>
<script>
import VisitListItem from './components/visitListItem.vue';
import {Button, Tabs, Tab, Empty, Loading} from 'vant';
import {fetchPhysicianMslCalls} from '@/api/kol';
import {
  getFirstUpperChar,
  getLocalStorage,
  setLocalStorage,
  debounce,
} from '@/utils/index';

export default {
  components: {
    VisitListItem,
    Button,
    Tabs,
    Tab,
    Empty,
    Loading,
  },
  data() {
    return {
      active: '0',
      allList: [],
      todayData: [],
      tomorrowData: [],
      previousData: [],
      lastList: [],
      loading: false,
    };
  },
  created() {
    this.getPhysicianMsICalls();
  },
  mounted() {},
  destroyed() {},
  methods: {
    getLocalStorage,
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async getPhysicianMsICalls() {
      this.loading = true;
      try {
        const res = await fetchPhysicianMslCalls({
          is_co_call: Number(this.active),
          call_msl_user_id: getLocalStorage('user_id'),
        });
        this.allList = res.value;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        res.value.forEach((item) => {
          const itemDate = new Date(item.call_time);
          itemDate.setHours(0, 0, 0, 0);

          if (itemDate.getTime() === today.getTime()) {
            this.todayData.push(item);
          } else if (
            itemDate.getTime() ===
            today.getTime() + 24 * 60 * 60 * 1000
          ) {
            this.tomorrowData.push(item);
          } else if (itemDate.getTime() < today.getTime()) {
            this.previousData.push(item);
          }
        });

        console.log(
          'this.convertTo2DArray(res.value)',
          this.convertTo2DArray(res.value),
        );
        this.lastList = this.convertTo2DArray(res.value);
        this.scrollToBottom();
      } finally {
        this.loading = false;
      }
    },
    convertTo2DArray(inputArray) {
      const dateMap = {};

      for (let i = 0; i < inputArray.length; i++) {
        const element = inputArray[i];
        const callTime = element.call_time.split('T')[0]; // 截取日期部分
        const currentDate = new Date().toISOString().split('T')[0]; // 当前日期
        const nextDate = new Date(Date.now() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split('T')[0]; // 明天的日期

        let time;
        if (callTime !== currentDate && callTime !== nextDate) {
          time = new Date(callTime).toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }); // 格式化日期为 "YYYY年MM月DD日"
        }
        if (callTime === currentDate) {
          time = '今天';
        }
        if (callTime === nextDate) {
          time = '明天';
        }

        if (Object.prototype.hasOwnProperty.call(dateMap, time)) {
          dateMap[time].list.push(element);
        } else {
          dateMap[time] = {time, list: [element]};
        }
      }

      const resultArray = Object.values(dateMap);
      return resultArray;
    },
    changeTab() {
      this.lastList = [];
      this.getPhysicianMsICalls();
    },
    changeColor(e) {
      if (e === '今天') {
        return '#00BDBC';
      } else if (e === '明天') {
        return '#FD9700';
      } else {
        return '#B2B2B2';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home-content {
  min-height: calc(100% - 20px);
  padding: 10px;

  .date-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
  }

  .floating {
    position: absolute;
    bottom: 90px;
    right: 10px;
  }

  ::v-deep .van-tabs__nav--card {
    margin: 0;
  }

  ::v-deep .van-tabs__nav {
    border-radius: 50px;
    overflow: hidden;
  }
}
</style>
